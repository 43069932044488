<template>
  <div class="HomeView">
    <div class="col-lg-12 control-section">
      <h2>Acceder</h2>
      <form action="/clientes">
        <br>
        <input type="text" class="nombreUsuario" id="user" placeholder="Nombre de usuario" value="" required pattern="^[A-Z][a-zA-ZÀ-ÖØ-öø-ÿ]+{1,20}" title="El nombre de usuario solo puede contener letras">
        <br>
        <input type="password" id="password" placeholder="Contraseña" value="" required pattern="[0-9]{6,10}" title="La contraseña solo puede contener numeros y son mínimo 6 caracteres.">
        <br><br>
        <button class="entrar" type="submit" value="entrar">Entrar</button>
      </form>
    </div> 
  </div>
</template>

<style>
  @import '../styles/homeView.css';
</style>

<script>
  export default {
    name: 'Home',
    data: () => ({}),
  }
</script>
