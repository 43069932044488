<template>
  <v-app id="app">
    <div class="row">
      <div id="logo" class="col-md-6">
        <img alt="Disauto logo" src="./assets/disauto.png">
      </div>
    </div>
    <div class="body">
      <router-view/>
    </div>
  </v-app>
</template>

<style>
  @import './styles/app.css';
</style>

<script>
  export default {
    name: 'App',
    data: () => ({}),
  };
</script>
